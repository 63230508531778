/*
 * Copyright 2015-2020 The OpenZipkin Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the License
 * is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
 * or implied. See the License for the specific language governing permissions and limitations under
 * the License.
 */

import shortid from 'shortid';

type Criterion = {
  key: string;
  value: string;
  id: string; // for React key props.
};

export const newCriterion = (key: string = '', value: string = '') => {
  return {
    key,
    value,
    id: shortid.generate(),
  };
};

export default Criterion;
